import React, { useEffect, useState } from "react";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Container,
} from "@mui/material";
import { useStyles } from "./style.module";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { isEmpty, isNull } from "lodash";

export const TableComponent = ({
  rows,
  columns,
  startActions,
  actions,
  editRow,
  fetchData,
  autoLoad = false,
  isLoading,
  tableHeight = 600,
  downloading,
  downloadAction,
  download = false,
}) => {
  const classes = useStyles(columns[0]);
  const [page, setPage] = useState(1);

  const handleChange = (event, newPage) => setPage(newPage);

  // If the rows are refreshed outside this component, the actual page (current_page) of the data could end up being
  // different from "page". So we track the current_page of the data with a useEffect.
  const dataCurrentPage = rows.current_page;
  useEffect(() => {
    if (dataCurrentPage !== undefined && page !== dataCurrentPage) {
      setPage(dataCurrentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurrentPage]);

  // Fetch data after changing the page
  useEffect(() => {
    // "page !== dataCurrentPage" is used to avoid fetching the data two times when the useEffect above sets the page.
    if (
      ((autoLoad && page >= 1) || page >= 1) &&
      (dataCurrentPage === undefined || page !== dataCurrentPage)
    ) {
      fetchData(page);
    }
  }, [page]);

  return (
    <Box mt={4}>
      <>
        <TableContainer sx={{ height: tableHeight }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, i) =>
                  i === 0 ? (
                    <TableCell
                      className={`${classes.sticky}`}
                      key={i}
                      align="left"
                      sx={{
                        backgroundColor: "#f4f4f4",
                        zIndex: 1000,
                        minWidth: 130,
                      }}
                    >
                      <b>{column.label}</b>
                    </TableCell>
                  ) : (
                    <TableCell
                      className={classes.row}
                      key={i}
                      align="left"
                      sx={{ backgroundColor: "#f4f4f4", minWidth: 200 }}
                    >
                      <b>{column.label}</b>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <Box
                    sx={{
                      height: tableHeight,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner animation="border" variant="primary" />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {rows.data &&
                  rows.data.map((row, i) => (
                    <TableRow key={i} onClick={() => editRow && editRow(row)}>
                      {columns.map((column, idx) =>
                        idx === 0 ? (
                          startActions ? (
                            <>
                              <TableCell
                                className={classes.sticky}
                                align="center"
                              >
                                {startActions(row)}
                              </TableCell>
                            </>
                          ) : (
                            <TableCell className={classes.sticky} key={idx}>
                              {column.link ? (
                                <a
                                  href={`/#${column.link}${
                                    row[column.keyValue]
                                  }`}
                                  style={{ color: "#6e0072" }}
                                >
                                  {row[column.keyValue]}
                                </a>
                              ) : (
                                row[column.keyValue]
                              )}
                            </TableCell>
                          )
                        ) : column.keyValue !== "" ? (
                          <TableCell
                            sx={{
                              minWidth: column.minWidth
                                ? column.minWidth
                                : "auto",
                            }}
                            className={classes.row}
                            align="left"
                            key={idx}
                          >
                            {column.link ? (
                              <a
                                href={`/#${column.link}${row[column.keyValue]}`}
                                style={{ color: "#6e0072" }}
                              >
                                {row[column.keyValue]}
                              </a>
                            ) : (
                              row[column.keyValue]
                            )}
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{
                              minWidth: column.minWidth
                                ? column.minWidth
                                : "auto",
                            }}
                            className={classes.row}
                            align="left"
                            key={idx}
                          >
                            <Typography
                              variant="body2"
                              sx={{ color: "#c0c0c0" }}
                            >
                              N/A
                            </Typography>
                          </TableCell>
                        )
                      )}
                      {actions ? (
                        <>
                          <TableCell
                            className={classes.row}
                            align="center"
                            sx={{ display: "flex" }}
                          >
                            {actions()}
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Box mt={2} mr={5} display="flex" justifyContent="space-between">
          <Box>
            {download === true && !isEmpty(rows.data) ? (
              downloading ? (
                "downloading....."
              ) : (
                <Button
                  variant="link"
                  className="shadow-none"
                  onClick={() => downloadAction()}
                >
                  <img
                    src="/csv.png"
                    alt="download-report"
                    width={28}
                    height={28}
                  />
                </Button>
              )
            ) : null}
          </Box>
          <Box>
            <Pagination
              count={Number(rows.last_page)}
              page={page}
              onChange={handleChange}
              showFirstButton
              showLastButton
            />
          </Box>
        </Box>
      </>
    </Box>
  );
};
